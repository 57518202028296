import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { scrollProducts, textLabel } from "../components/functions";
import CategoriesMenu1 from "../components/CategoriesMenu1";
import StoreHeader from "../components/StoreHeader";
import Cart from "../components/Cart";
import { useParams } from "react-router-dom";
import CategoriesMenu2 from "../components/CategoriesMenu2";
import CategoriesMenu2Button from "../components/CategoriesMenu2/CategoriesMenu2Button";
import CmsPopups from "../components/CmsPopups";
import Label from "../components/Label";
import Product from "../components/Product";
import SearchIcon from "../components/icons/SearchIcon";

function Store() {
	const {
		settings,
		courses,
		getTranslatedString,
		setCategory,
		cart,
		labels,
		lang,
		cartEnabled,
		setCartEnabled,
		storePopupVisible,
		setStorePopupVisible,
	} = useContext(AppContext);
	const [src, setSrc] = useState("");
	const [srcProducts, setSrcProducts] = useState([]);
	let params = useParams();

	let sclollTimeOut;

	useEffect(() => {
		console.log(params.categoryId);
		if (params.categoryId) scrollProducts(params.categoryId);
	}, []);

	useEffect(() => {
		setCategory(false);
		document.getElementById("main").addEventListener("scroll", handleScroll);

		return () => {
			document.getElementById("main")?.removeEventListener("scroll", handleScroll);
		};
	}, [courses]);

	useEffect(() => {
		checks();
	}, [cart, storePopupVisible, cartEnabled]);

	useEffect(() => {
		console.log("cartEnabled set to", cartEnabled);
	}, [cartEnabled]);

	useEffect(() => {
		filter();
	}, [src]);

	const checks = () => {
		console.log("checks", cart.delivery_type, cart.shop_id, cart.selected_time);
		let valid = true;
		if (cart) {
			if (!cart.delivery_type) valid = false;
			if (cart.delivery_type == "takeaway" && !cart.shop_id) valid = false;
			if (cart.delivery_type == "delivery") {
				if (!cart.hotel_id) {
					if (!cart.selected_address || cart.selected_address.covered == 0) valid = false;
				} else {
				}
			}
			if (!cart.selected_time) valid = false;
		}
		console.log("checks", valid);
		if (!valid) {
			if (cartEnabled) setStorePopupVisible(true);
		} else setCartEnabled(true);
	};

	const handleScroll = (e) => {
		clearTimeout(sclollTimeOut);
		sclollTimeOut = setTimeout(() => {
			console.log("handleScroll");
			for (const categoryBox of document.getElementsByClassName("category-box")) {
				const rect = categoryBox.getBoundingClientRect();
				//console.log(categoryBox.id, rect.top, rect.bottom);
				if (rect.top < window.innerHeight && rect.bottom >= 120) {
					console.log(categoryBox.id, "active");
					let c = false;
					for (const course of courses) {
						for (const category of course.categories) {
							/*console.log(
								"category-box-" + category.id,
								categoryBox.id,
								"category-box-" + category.id == categoryBox.id
							);*/
							if ("category-box-" + category.id == categoryBox.id) c = category;
						}
					}
					if (c) {
						setCategory(c);
						if (settings.website.categories_menu_type_new != 2) {
							const containerLeftOffset =
								document.getElementsByClassName("categories-menu-1")[0].offsetLeft;
							const buttonLeftOffset = document.getElementById(
								"category-button-" + c.id
							).offsetLeft;
							console.log(
								containerLeftOffset,
								buttonLeftOffset,
								buttonLeftOffset - containerLeftOffset
							);
							document.getElementsByClassName("categories-menu-1")[0].scroll({
								left: buttonLeftOffset - containerLeftOffset,
								behavior: "smooth",
							});
						}
						break;
					}
				}
			}
		}, 100);
	};

	function filter() {
		console.log("filter");
		let arr = [];
		if (src) {
			courses.map((course) =>
				course.categories.map((category) =>
					category.products.map((product) => {
						console.log(product.name, src, product.name.includes(src));
						if (product.name.toLowerCase().includes(src.toLowerCase()))
							arr.push(product);
					})
				)
			);
		}
		arr.sort(function (a, b) {
			return a["name"] > b["name"] ? 1 : -1;
		});
		setSrcProducts(arr);
	}

	return (
		<>
			{cartEnabled && <StoreHeader setPopupVisible={setStorePopupVisible} />}
			{settings.website.categories_menu_type_new != 2 && <CategoriesMenu1 />}
			{settings.website.categories_menu_type_new == 2 && <CategoriesMenu2Button />}
			<div
				className={
					"store-columns screen-container" +
					(settings.website.categories_menu_type_new == 2 ? " three-columns" : "")
				}
			>
				{settings.website.categories_menu_type_new == 2 && <CategoriesMenu2 />}
				<div id="store">
					<div className="src">
						<span>
							<SearchIcon></SearchIcon>
						</span>
						<input
							type="text"
							placeholder={textLabel(labels, 202, lang)}
							value={src}
							onChange={(e) => setSrc(e.target.value)}
						/>
						{src && (
							<button onClick={() => setSrc("")}>
								x
							</button>
						)}
					</div>
					{src && (
						<div>
							<h1>
								<Label number={201} />
							</h1>
							<div className="category-box">
								<ProductsList products={srcProducts} />
							</div>
						</div>
					)}
					{!src &&
						courses.map((course, i) => (
							<div key={i}>
								{course.name && <h1>{getTranslatedString(course, "name")}</h1>}
								{course.categories.map(
									(category, n) =>
										category.products.length > 0 && (
											<div
												key={n}
												id={"category-box-" + category.id}
												className="category-box"
											>
												<div
													id={"category-" + category.id}
													className="anchor"
												></div>
												<h2>{getTranslatedString(category, "name")}</h2>
												<p className="smoot">
													{getTranslatedString(category, "description")}
												</p>
												<ProductsList
													products={category.products}
													category={category}
												/>
											</div>
										)
								)}
							</div>
						))}
				</div>
				<Cart cartEnabled={cartEnabled} setCartEnabled={setCartEnabled} />
			</div>
			<CmsPopups mode="shop" shopId={cart.shop_id ? cart.shop_id : false} />
		</>
	);
}

function ProductsList({ products, category = false }) {
	return (
		<div className="products">
			{products.map((product, n) => (
				<Product key={n} product={product} category={category} />
			))}
		</div>
	);
}

export default Store;
