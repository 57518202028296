import Label from "../components/Label";
import api from "../components/api";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { formatOrderStatus } from "../components/functions";
import UserboardMenu from "../components/UserboardMenu";
import moment from "moment/moment";
import PopUp from "../components/PopUp";
import TrashIcon from "../components/icons/TrashIcon";
import SearchIcon from "../components/icons/SearchIcon";
import CloseIcon from "../components/icons/CloseIcon";

function Bookings() {
	const { settings, setLoading, setConfirm, user } = useContext(AppContext);
	const [reservations, setReservations] = useState(false);
	const [reservation, setReservation] = useState(false);

	useEffect(() => {
		loadOrders(); // eslint-disable-next-line
	}, []);

	const loadOrders = async () => {
		setLoading(true);
		const response = await api.postProtected("/reservations/list/", {
			orderby: "id DESC",
			src: [{ name: "customer_id", value: user.id, compare: "equal" }],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setReservations(response.rows);
	};

	const editReservation = async (id, request) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.postProtected("/reservations/edit/" + id + "/", request);
				if (response.success == 0)
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				else loadOrders();
			},
		});
	};

	return (
		<>
			<div className="page">
				<div className="userboard">
					<UserboardMenu selected="bookings" />
					<div className="box">
						<h1>
							<Label number={53} />
						</h1>
						{reservations &&
							reservations.map((reservation, i) => (
								<div key={i} className="orders-list">
									<div>
										N°{reservation.id}
										<div className="small">
											{moment(reservation.date, "YYYY-MM-DD HH:mm:ss").format(
												"DD/MM/YYYY HH:mm"
											)}
										</div>
									</div>
									<div className="small">
										<Label number={formatOrderStatus(reservation.status)} />
									</div>
									<div className="buttons horizontal">
										{(reservation.status == "unconfirmed" ||
											reservation.status == "confirmed") &&
											settings.reservations.cancellation_minutes &&
											moment(reservation.date, "YYYY-MM-DD HH:mm:ss")
												.subtract(
													settings.reservations.cancellation_minutes,
													"minutes"
												)
												.format("X") -
											moment().format("X") >
											0 && (
												<button
													className="button button-primary"
													onClick={() =>
														editReservation(reservation.id, {
															status: "cancel_request",
														})
													}
												>
													<TrashIcon></TrashIcon>
												</button>
											)}
										<button
											className="button button-primary"
											onClick={() => setReservation(reservation)}
										>
											<SearchIcon></SearchIcon>
										</button>
									</div>
								</div>
							))}
					</div>
				</div>
			</div>
			<Sheet reservation={reservation} setReservation={setReservation} />
		</>
	);
}

export default Bookings;

function Sheet({ reservation, setReservation }) {
	if (!reservation) return null;

	return (
		<PopUp>
			<div className="header">
				N°{reservation.id}
				<button onClick={() => setReservation(false)}>
					<CloseIcon></CloseIcon>
				</button>
			</div>
			<div className="content">
				<div>
					<div>
						<Label number={9} />: {reservation.shop.name}
					</div>
					<div>
						<Label number={10} />:{" "}
						{moment(reservation.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm")}
					</div>
					<div>
						<Label number={69} />:{" "}
						<Label number={formatOrderStatus(reservation.status)} />
					</div>
					<div>
						<Label number={70} />: {reservation.covers}
					</div>
				</div>
			</div>
		</PopUp>
	);
}
