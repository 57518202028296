import { useContext, useState } from "react";
import { AppContext } from "../App";
import Label from "./Label";
import GoogleAddress from "./GoogleAddress";
import moment from "moment/moment";
import api from "./api";
import ExpireBox from "./ExpireBox";
import DeliveryIcon from "./icons/DeliveryIcon";
import EditIcon from "./icons/EditIcon";
import TrashIcon from "./icons/TrashIcon";
import TakeawayIcon from "./icons/TakeawayIcon";


function StorePopUp() {
	const {
		settings,
		cart,
		editCart,
		user,
		setLoading,
		loadCart,
		setConfirm,
		hasDelivery,
		hasTakeaway,
		setStorePopupVisible,
		setCartEnabled,
	} = useContext(AppContext);
	const [editAddress, setEditAddress] = useState(false);

	const addAddress = async (request) => {
		setLoading(true);
		let response;
		request.customer_id = user.id;
		response = await api.postProtected("/addresses_delivery/add/", request);
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else loadCart();
	};

	const deleteAddressesDelivery = async (id) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.getProtected("/addresses_delivery/delete/" + id + "/");
				if (response.success == 0)
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				else loadCart();
			},
		});
	};

	const saveAddress = async (address) => {
		setLoading(true);
		const response = await api.postProtected(
			"/addresses_delivery/edit/" + editAddress.id + "/",
			address
		);
		setLoading(false);
		if (response.success == 0) {
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		} else {
			setEditAddress(false);
			loadCart();
		}
	};

	return (
		<div id="store-popup">
			<div className="content">
				{!cart.hotel_id && hasDelivery == 1 && hasTakeaway == 1 && (
					<>
						<div className="title">
							<Label number={185} />
						</div>
						<div className="delivery-type-selector">
							<button
								onClick={() => editCart({ delivery_type: "delivery" })}
								className={cart.delivery_type == "delivery" ? "active" : ""}
							>
								<DeliveryIcon></DeliveryIcon>
								<div>
									<Label number={7} />
								</div>
							</button>
							<button
								onClick={() => editCart({ delivery_type: "takeaway" })}
								className={cart.delivery_type == "takeaway" ? "active" : ""}
							>
								<TakeawayIcon></TakeawayIcon>
								<div>
									<Label number={8} />
								</div>
							</button>
						</div>
					</>
				)}
				{(cart.delivery_type == "takeaway" ||
					settings.orders.disable_multistore_delivery == 1) &&
					cart.shop_id && (
						<div className="box">
							<div className="box-header">
								<Label number={9} />
								<button onClick={() => editCart({ shop_id: null })}>
									<Label number={75} />
									<EditIcon></EditIcon>
								</button>
							</div>
							{cart.shop && (
								<>
									<div className="title">{cart.shop.name}</div>
									{cart.shop.address +
										" " +
										cart.shop.number +
										", " +
										cart.shop.city}
								</>
							)}
						</div>
					)}
				{user && cart.delivery_type == "delivery" && cart.delivery_address_id && (
					<div className="box">
						<div className="box-header">
							<Label number={12} />
							<button onClick={() => editCart({ delivery_address_id: null })}>
								<Label number={75} />
								<EditIcon></EditIcon>
							</button>
						</div>
						{cart.delivery_address &&
							cart.delivery_address.address +
							" " +
							cart.delivery_address.number +
							", " +
							cart.delivery_address.city +
							(cart.delivery_address.doorphone
								? " (" + cart.delivery_address.doorphone + ")"
								: "")}
					</div>
				)}
				{!user && cart.delivery_type == "delivery" && cart.temp_address && (
					<div className="box">
						<div className="box-header">
							<Label number={12} />
							<button onClick={() => editCart({ temp_address: null })}>
								<Label number={75} />
								<EditIcon></EditIcon>
							</button>
						</div>
						{cart.temp_address &&
							cart.temp_address.address +
							" " +
							cart.temp_address.number +
							", " +
							cart.temp_address.city +
							(cart.temp_address.doorphone
								? " (" + cart.temp_address.doorphone + ")"
								: "")}
						{cart.temp_address.covered == 0 && (
							<div className="error">
								<Label number={81} />
							</div>
						)}
					</div>
				)}
				{cart.hotel_id && (
					<div className="box">
						<div className="box-header">
							<Label number={12} />
							{!cart.hotel_id && (
								<button onClick={() => editCart({ delivery_address_id: null })}>
									<Label number={75} />
									<EditIcon></EditIcon>
								</button>
							)}
						</div>
						{cart.delivery_address &&
							cart.hotel.name +
							" - " +
							cart.delivery_address.address +
							" " +
							cart.delivery_address.number +
							", " +
							cart.delivery_address.city}
					</div>
				)}
				{(cart.hotel_id ||
					(cart.delivery_type == "takeaway" && cart.shop_id) ||
					(cart.delivery_type == "delivery" &&
						((user && cart.delivery_address_id) ||
							(!user && cart.temp_address && cart.temp_address.covered == 1)))) && (
						<>
							<div className="box">
								<div className="box-header">
									<Label number={10} />
								</div>
								<select
									value={moment(cart.delivery_date, "YYYY-MM-DD HH:mm:ss").format(
										"YYYY-MM-DD 00:00:00"
									)}
									onChange={(e) => editCart({ delivery_date: e.target.value })}
								>
									{cart.availables_delivery_dates.map((date, i) => (
										<option key={i} value={date}>
											{moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
										</option>
									))}
								</select>
							</div>
							<div className="box">
								<div className="box-header">
									<Label number={11} />
								</div>
								{cart.availables_delivery_times.length > 0 ? (
									<select
										value={cart.delivery_date}
										onChange={(e) => editCart({ delivery_date: e.target.value })}
									>
										{settings.orders.disable_time_preselection == 1 && (
											<option value=""></option>
										)}
										{cart.availables_delivery_times.map((time, i) => (
											<option key={i} value={time.value}>
												{time.label}
											</option>
										))}
									</select>
								) : (
									<div className="warning">
										<Label number={114} />
									</div>
								)}
								<ExpireBox block={true} />
							</div>
						</>
					)}
				{(cart.delivery_type == "takeaway" ||
					settings.orders.disable_multistore_delivery == 1) &&
					!cart.shop_id && (
						<>
							<div className="title">
								<Label number={32} />
							</div>
							{cart.availables_shops.map((shop, i) => (
								<button
									key={i}
									onClick={() => editCart({ shop_id: shop.id })}
									className="button"
								>
									{shop.name}
									<div className="small">
										{shop.address + " " + shop.number + ", " + shop.city}
									</div>
								</button>
							))}
						</>
					)}
				{!cart.hotel_id &&
					!user &&
					cart.delivery_type == "delivery" &&
					!cart.temp_address &&
					settings.google.api_key && (
						<>
							<div className="title">
								<Label number={33} />
							</div>
							<GoogleAddress
								address={cart.temp_address}
								onCange={(address) => editCart({ temp_address: address })}
							/>
						</>
					)}
				{!cart.hotel_id &&
					user &&
					cart.delivery_type == "delivery" &&
					!cart.delivery_address_id &&
					(settings.orders.disable_multistore_delivery != 1 || cart.shop_id) && (
						<>
							{!editAddress && (
								<>
									<div className="title spacer text-center">
										<Label number={33} />
									</div>
									<GoogleAddress
										address={cart.delivery_address}
										onCange={(address) => addAddress(address)}
										emptyOnSubmit={true}
									/>
									<div className="title spacer text-center">
										<Label number={80} />
									</div>
									{cart?.availables_addresses_delivery?.map((address, i) => (
										<div key={i} className="address-buttons">
											<button
												onClick={() => {
													if (address.covered == 1)
														editCart({
															delivery_address_id: address.id,
														});
												}}
											>
												{address.address + " " + address.number}
												<div>{address.zipcode + " " + address.city}</div>
												<div className="small">
													<Label number={34} />: {address.doorphone}
												</div>
												{address.covered == 0 && (
													<div className="error">
														<Label number={81} />
													</div>
												)}
											</button>
											<div className="buttons">
												<button onClick={() => setEditAddress(address)}>
													<EditIcon></EditIcon>
												</button>
												<button
													onClick={() =>
														deleteAddressesDelivery(address.id)
													}
												>
													<TrashIcon></TrashIcon>
												</button>
											</div>
										</div>
									))}
								</>
							)}
							{editAddress && (
								<>
									<GoogleAddress
										address={editAddress}
										onCange={(address) => saveAddress(address)}
										emptyOnSubmit={false}
									/>
									<button
										onClick={() => {
											setEditAddress(false);
										}}
										className="button button-primary"
									>
										<Label number={129} />
									</button>
								</>
							)}
						</>
					)}
				{cart.shop_id &&
					moment(cart.delivery_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm") != "00:00" ? (
					<button
						onClick={() => setStorePopupVisible(false)}
						className="button button-primary"
					>
						<Label number={76} />
					</button>
				) : (
					!editAddress && (
						<button
							onClick={() => {
								setCartEnabled(false);
								setStorePopupVisible(false);
							}}
							className="button button-primary"
						>
							<Label number={129} />
						</button>
					)
				)}
			</div>
		</div>
	);
}

export default StorePopUp;
