import * as React from "react";

function InfoIcon(props) {
	return (
		 <svg
      viewBox="0 0 460 1000"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M352 0c32 0 56.667 9 74 27s26 41 26 69c0 33.333-13 62.667-39 88s-57.667 38-95 38c-32 0-56.667-8.667-74-26s-25.333-41.333-24-72c0-30.667 11.667-59 35-85s55.667-39 97-39M146 1000c-66.667 0-84.667-59.333-54-178l60-254c9.333-37.333 9.333-56 0-56-8 0-26 6-54 18s-52 24.667-72 38L0 524c60-52 123-94 189-126s116.333-48 151-48c52 0 64 54 36 162l-70 266c-10.667 42.667-8.667 64 6 64 29.333 0 68.667-20 118-60l30 40c-56 57.333-114.333 101.333-175 132s-107 46-139 46" />
    </svg>
	);
}

export default InfoIcon;
