import { Link } from "react-router-dom";
import Label from "../../../components/Label";
import "./style.css";
import { useContext } from "react";
import { AppContext } from "../../../App";
import InfoIcon from "../../../components/icons/InfoIcon";

export default function Template2({ shops }) {
	const { getTranslatedString } = useContext(AppContext);

	const scroll = (id) => {
		console.log("scroll");
		const element = document.getElementById(id);
		if (element) element.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div className="page shops-template-2">
			<h1 id="page-header">
				<Label number={29} />
			</h1>
			<div className="shops-menu small-scroll">
				{shops.map((shop, i) => (
					<button key={i} onClick={() => scroll("anchor-" + i)}>
						{shop.name}
					</button>
				))}
			</div>
			<div className="list">
				{shops.map((shop, i) => (
					<div key={i} className="box" id={"anchor-" + i}>
						<div
							className="img"
							style={{ backgroundImage: shop.img ? "url(" + shop.img + ")" : "none" }}
						/>
						<div className="info">
							<div className="address">
								{shop.address +
									" " +
									shop.number +
									", " +
									shop.zipcode +
									", " +
									shop.city +
									" (" +
									shop.province +
									")"}
							</div>
							<div className="title">{shop.name}</div>
							<div className="contacts">
								{shop.email && (
									<div>
										<a href={"mailto:" + shop.email}>{shop.email}</a>
									</div>
								)}
								{shop.phone && (
									<div>
										<a href={"tel:" + shop.phone}>{shop.phone}</a>
									</div>
								)}
							</div>
							{shop.times_text && (
								<div className="times">
									<div className="title">
										<Label number={148} />
									</div>
									<div
										dangerouslySetInnerHTML={{
											__html: getTranslatedString(shop, "times_text"),
										}}
									/>
								</div>
							)}
							<div className="buttons">
								{(shop.delivery == 1 || shop.takeaway == 1) && (
									<Link to="/store">
										<Label number={1} />
									</Link>
								)}
								{shop.active_reservations == 1 && (
									<>
										{!shop.reservations_ext_url ? (
											<Link to="/booking">
												<Label number={2} />
											</Link>
										) : (
											<a href={shop.reservations_ext_url} target="_blank">
												<Label number={2} />
											</a>
										)}
									</>
								)}
								<Link to={"/shops/" + shop.id}>
									<InfoIcon></InfoIcon>

								</Link>
							</div>
							<div className="addinfo-label">
								<Label number={187} />{" "}
								<Link to={"/shops/" + shop.id}>
									<Label number={188} />
								</Link>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
