import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import Product from "../components/Product";
import PopUp from "../components/PopUp";
import Label from "../components/Label";
import CloseIcon from "../components/icons/CloseIcon";

function RelatedProductsPopup() {
	const { relatedProducts, setRelatedProducts, setLoadRelated } = useContext(AppContext);

	useEffect(() => {
		setLoadRelated(false);
		return () => setLoadRelated(true);
	}, []);

	return (
		<PopUp className="related-products-popup">
			<div className="header">
				<div>
					<Label number={204} />
				</div>
				<button onClick={() => setRelatedProducts(false)}>
					<CloseIcon></CloseIcon>
				</button>
			</div>
			<div className="content">
				<div>
					<Label number={205} />
				</div>
				<div className="products-list">
					{relatedProducts.map((product, n) => (
						<Product key={n} product={product} />
					))}
				</div>
			</div>
		</PopUp>
	);
}

export default RelatedProductsPopup;
