import { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
import ArrowDownIcon from "./icons/ArrowDownIcon";

function CmsMenu({ data, type, className = "button" }) {
	const elements = JSON.parse(data.menu);
	return (
		<>
			{elements.map((element, i) => (
				<Element key={i} data={element} type={type} className={className} />
			))}
		</>
	);
}

function Element({ data, type, className }) {
	const { settings, lang } = useContext(AppContext);
	const submenu = useRef();

	const showHideSubmenu = () => {
		console.log("showHideSubmenu");
		if (submenu.current.style.display == "none") submenu.current.style.display = "block";
		else submenu.current.style.display = "none";
	};

	const hideSubmenu = () => {
		console.log("hideSubmenu");
		//console.log(parentSubmenu);
		//if (parentSubmenu.current) parentSubmenu.current.style.display = "none";
		console.log(document.querySelectorAll(".submenu"));
		document.querySelectorAll(".submenu").forEach((el) => (el.style.display = "none"));
		const el = document.getElementById("menu");
		el.classList.remove("visible");
	};

	const getLabel = (obj) => {
		if (settings.mandant.multilanguage == 0) return obj["labels-it"];
		if (obj["labels-" + lang]) return obj["labels-" + lang];
		return obj["labels-it"];
	};

	return (
		<>
			{(!data.childs || data.childs.length == 0) && (
				<>
					{data.action == "store" && (
						<Link className={className} to="/store" onClick={hideSubmenu}>
							{getLabel(data)}
						</Link>
					)}
					{data.action == "reservations" && (
						<Link className={className} to="/booking" onClick={hideSubmenu}>
							{getLabel(data)}
						</Link>
					)}
					{data.action == "url" && (
						<a
							className={className}
							href={data.url}
							target="_blank"
							onClick={hideSubmenu}
						>
							{getLabel(data)}
						</a>
					)}
					{data.action == "page" && (
						<Link
							className={className}
							to={"/cms/" + data.page_id}
							onClick={hideSubmenu}
						>
							{getLabel(data)}
						</Link>
					)}
					{data.action == "shops" && (
						<Link className={className} to="/shops" onClick={hideSubmenu}>
							{getLabel(data)}
						</Link>
					)}
					{data.action == "news" && (
						<Link className={className} to="/news" onClick={hideSubmenu}>
							{getLabel(data)}
						</Link>
					)}
					{data.action == "promo" && (
						<Link className={className} to="/promo" onClick={hideSubmenu}>
							{getLabel(data)}
						</Link>
					)}
				</>
			)}
			{data.childs && data.childs.length > 0 && (
				<div className={"submenu-container " + type}>
					<button
						className={"submenu-button " + className}
						onClick={() => showHideSubmenu()}
					>
						<span>{getLabel(data)}</span>
						<ArrowDownIcon></ArrowDownIcon>
					</button>
					<div className="submenu" ref={submenu} style={{ display: "none" }}>
						{data.childs.map((child, i) => (
							<Element key={i} data={child} type={type} className={className} />
						))}
					</div>
				</div>
			)}
		</>
	);
}

export default CmsMenu;
