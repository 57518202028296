import * as React from "react";

function FoursquareIcon(props) {
	return (
		 <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M17.549 5.557l-.403 2.113c-.049.229-.337.47-.605.47h-3.76c-.425 0-.729.296-.729.714v.463c0 .43.307.725.73.725h3.194c.298 0 .591.33.524.646l-.404 2.083c-.037.181-.237.475-.592.475h-2.609c-.477 0-.617.069-.936.454-.316.395-3.175 3.827-3.175 3.827-.026.033-.054.022-.054-.011V5.522c0-.271.234-.588.586-.588h7.756c.283 0 .549.269.477.618v.005zm.341 8.288c.111-.437 1.319-6.63 1.722-8.593m-1.489-2.311H8.457c-1.33 0-1.723 1.002-1.723 1.635v15.353c0 .71.381.975.596 1.062.214.087.807.161 1.163-.247 0 0 4.563-5.296 4.64-5.373.125-.118.125-.118.238-.118h2.954c1.245 0 1.438-.885 1.574-1.405.114-.429 1.325-6.622 1.721-8.595.307-1.496-.079-2.311-1.495-2.311h-.002z" />
    </svg>
	);
}

export default FoursquareIcon;
